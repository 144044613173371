import settlementApi from '@/api/settlementApi';
import organizationApi from '@/api/organizationApi';

export const getCurrencyAndIndexingUnitByCompany = async (
    { commit },
    dataToSend,
) => {
    const { data } = await settlementApi.post(
        `CurrencyAndIndexingUnit/SearchCurrencyAndIndexingUnitByCompanyId`,
        dataToSend,
    );
    let transformData = data.data.map((item) => {
        if (item.type === 1) {
            item.currencyDefinition.active = item.active;
            item.currencyDefinition.id = item.id;
            item.currencyDefinition.name = `${item.currencyDefinition.standard}`;
            item.currencyDefinition.type = item.type;
            item.currencyDefinition.default = item.default;
            return item.currencyDefinition;
        } else if (item.type === 2) {
            item.indexingUnitDefintion.active = item.active;
            item.indexingUnitDefintion.id = item.id;
            item.indexingUnitDefintion.name = `${item.indexingUnitDefintion.symbol} ${item.indexingUnitDefintion.name}`;
            item.indexingUnitDefintion.type = item.type;
            item.indexingUnitDefintion.default = item.default;
            return item.indexingUnitDefintion;
        }
    });
    commit('setCurrencyAndIndexingUnitByCompany', transformData);
    return transformData;
};

export const getActivitiesByProjectToGridAuditedByLeader = async (
    { commit },
    dataToSend,
) => {
    const { data } = await settlementApi.post(
        `OABUAuditedByLeader/GetActivitiesByProjectToGridAuditedByLeader`,
        dataToSend,
    );
    // commit('setActivitiesByProjectToGridAuditedByLeader', data.data);
    return data.data;
};

export const getProjectsByCompany = async ({ commit }) => {
    const { data } = await settlementApi.get(`OABUAuditedByLeader`);
    commit('setProjectsByCompany', data.data);
};

export const getGrid = async ({ commit }, configObject) => {
    const { data } = await organizationApi.post(
        `GridDefinition/SearchGridDefinition`,
        configObject,
    );
    commit('setGrid', data.data[0]);
};

export const getRatebooksByCompanyId = async ({ commit }, id) => {
    const { data } = await settlementApi.get(`RateBook/GetByCompany/${id}`);
    commit('setRateBooks', data.data);
    return data.data;
};

export const GetActivitiesByProjectToGridAuditedByLeaderDetail = async (
    { commit },
    { projectId, month, year, tradeAgreementId },
) => {
    const { data } = await settlementApi.get(
        `OABUAuditedByLeader/GetActivitiesByProjectDetailByProjectId/${projectId}/${month}/${year}/${tradeAgreementId}`,
    );
    commit('setActivitiesByProjectToGridAuditedByLeaderDetail', data.data);
};

export const GetBillableNotesByActivity = async ({ commit }, OABUId) => {
    const { data } = await settlementApi.get(
        `BillingNote/GetBillableNotesByActivity/${OABUId}`,
    );
    return data.data;
};

export const CreateBillingNote = async ({ commit }, dataToSend) => {
    const { data } = await settlementApi.post(`BillingNote`, dataToSend);
    return data;
};

export const UpdateBillingNote = async ({ commit }, dataToSend) => {
    const { data } = await settlementApi.put(`BillingNote`, dataToSend);
    return data;
};

export const ChangeActivityProject = async ({ commit }, dataToSend) => {
    const { data } = await settlementApi.post(
        `OABUAuditedByLeader/ChangeActivityProject`,
        dataToSend,
    );
    return data;
};

export const GetBlocksToSettle = async ({ commit }, dataToSend) => {
    const { data } = await settlementApi.post(
        `OABUAwaitingPreInvoice/GetBlocksToSettle`,
        dataToSend,
    );
    // commit('setBlocksToSettle', data.data);
    return data.data;
};

export const GetActivitiesbyArrayOABUId = async ({ commit }, dataToSend) => {
    const { data } = await settlementApi.post(
        `OABUAwaitingPreInvoice/GetActivitiesbyArrayOABUId`,
        dataToSend,
    );
    return data.data;
};

export const GetLogicBlockByArrayId = async ({ commit }, dataToSend) => {
    const { data } = await settlementApi.post(
        `OABUAwaitingPreInvoice/GetLogicBlockByArrayId`,
        dataToSend,
    );
    return data.data;
};

export const GetCodeBilling = async ({ commit }) => {
    const { data } = await settlementApi.get(
        `PreInvoiceByProject/GetCodeBilling`,
    );
    return data.data;
};

export const GetCurrencyById = async ({ commit }, id) => {
    const { data } = await settlementApi.get(`CurrencyAndIndexingUnit/${id}`);
    return data.data;
};

export const getTaxesByCompanyId = async ({ commit }, associtedCompanyId) => {
    const { data } = await settlementApi.get(
        `Taxes/GetTaxesByCompanyId/${associtedCompanyId}`,
    );
    return data.data;
};

export const getProfileTaxesById = async ({ commit }, taxProfileId) => {
    const { data } = await settlementApi.get(
        `ProfileTax/GetById/${taxProfileId}`,
    );
    return data.data;
};

export const SendOABUToAwaitingPreinvoice = async ({ commit }, dataToSend) => {
    const { data } = await settlementApi.post(
        `OABUAuditedByLeader/SendOABUToAwaitingPreinvoice`,
        dataToSend,
    );
    return data;
};

export const RecalculateTaRetainerInfo = async ({ commit }, dataToSend) => {
    const { data } = await settlementApi.post(
        `TARetainerLogic/RecalculateRetainerLogic`,
        dataToSend,
    );
    return data.data;
};

export const CreateAndProcessPreinvoiceByProject = async (
    { commit },
    dataToSend,
) => {
    const { data } = await settlementApi.post(
        `PreInvoiceByProject/CreateAndProcessPreinvoiceByProject`,
        dataToSend,
    );
    return data;
};

export const UpdatePreinvoiceByProject = async ({ commit }, dataToSend) => {
    const { data } = await settlementApi.put(`PreInvoiceByProject`, dataToSend);
    return data;
};

export const GetPreinvoiceByProjectById = async ({ commit }, preinvoiceId) => {
    const { data } = await settlementApi.get(
        `PreInvoiceByProject/${preinvoiceId}`,
    );
    commit('setPreinvoice', data.data);
    return data.data;
};

export const CreateBill = async ({ commit }, dataToSend) => {
    const { data } = await settlementApi.post(`Billing`, dataToSend);
    return data;
};

export const GetBillById = async ({ commit }, billId) => {
    const { data } = await settlementApi.get(`Billing/${billId}`);
    commit('setBilling', data.data);
};

export const GetPreinvoiceFilterSystem = async ({ commit }, dataToSend) => {
    const { data } = await settlementApi.post(
        `PreInvoiceByProject/GetPreInvoiceByProjectFilter`,
        dataToSend,
    );
    return data.data;
};

export const GetBillingFilter = async ({ commit }, dataToSend) => {
    const { data } = await settlementApi.post(
        `Billing/GetBillingFilter`,
        dataToSend,
    );
    return data.data;
};

export const getCurrencyByCompanyAndCurrencySeleted = async (
    { commit },
    setData,
) => {
    const { data } = await settlementApi.post(
        `CurrencyAndIndexingUnit/SearchCurrencyByIndexingUnitByCompanyId`,
        setData,
    );
    return data.data;
};

export const CreateRatebook = async ({ commit }, dataToSend) => {
    const { data } = await settlementApi.post(`RateBook`, dataToSend);
    commit('management/resultPut', data, { root: true });
    return data;
};

export const GetRatebookById = async ({ commit }, companyId) => {
    const { data } = await settlementApi.get(`RateBook/${companyId}`);
    return data.data;
};

export const UpdateRatebook = async ({ commit }, dataToSend) => {
    const { data } = await settlementApi.put(`RateBook`, dataToSend);
    commit('management/resultPut', data, { root: true });
    return data;
};

export const DeleteRatebook = async ({ commit }, ratebook) => {
    const { data } = await settlementApi.delete(`RateBook`, {
        data: { id: ratebook },
    });
    commit('management/resultPut', data, { root: true });
    return data;
};

export const CreateTax = async ({ commit }, dataToSend) => {
    const { data } = await settlementApi.post(`Taxes`, dataToSend);
    commit('management/resultPut', data, { root: true });
    return data;
};

export const UpdateTax = async ({ commit }, dataToSend) => {
    const { data } = await settlementApi.put(`Taxes`, dataToSend);
    commit('management/resultPut', data, { root: true });
    return data;
};

export const DeleteTax = async ({ commit }, id) => {
    const { data } = await settlementApi.delete(`Taxes`, { data: { id } });
    commit('management/resultPut', data, { root: true });
    return data;
};

export const getInvoicesByClient = async ({ commit }, idClient) => {
    if (idClient != null) {
        const { data } = await settlementApi.post(
            `Billing/GetByClientId`,
            idClient,
        );
        commit('setInvoicesByClient', data.data);
    } else {
        commit('setInvoicesByClient', []);
    }
};

export const RecalculateTaStaticFeeInfo = async ({ commit }, dataToSend) => {
    const { data } = await settlementApi.post(
        `FixedAmountLogic/RecalculateFixedAmountLogic`,
        dataToSend,
    );
    return data.data;
};

export const RecalculateByService = async ({ commit }, blockInfo) => {
    const { data } = await settlementApi.post(
        `TAByServiceLogic/Recalculate`,
        blockInfo,
    );

    return data.data;
};

export const RecalculateByHour = async ({ commit }, blockInfo) => {
    const { data } = await settlementApi.post(
        `TAByHourLogic/Recalculate`,
        blockInfo,
    );

    return data.data;
};

export const RecalculateByNotBillable = async ({ commit }, blockInfo) => {
    const { data } = await settlementApi.post(
        `TANotBillableLogic/Recalculate`,
        blockInfo,
    );

    return data.data;
};

export const CreateTALogicBlockProcess = async (
    { commit },
    blockInfoProcess,
) => {
    const { data } = await settlementApi.post(
        `PreInvoiceByProject/CreateTALogicBlockProcess`,
        blockInfoProcess,
    );

    return data;
};

export const GetOABUAuditedByLeaderIndicator = async (
    { commit },
    companyIdrequest,
) => {
    const { data } = await settlementApi.get(
        `OABUAuditedByLeader/GetOABUAuditedByLeaderIndicator/${companyIdrequest}`,
    );

    commit('setOABUAuditedByLeaderIndicator', data.data);
};

export const GetOABUAwaitingPreinvoiceBlocksIndicator = async (
    { commit },
    companyIdRequest,
) => {
    const { data } = await settlementApi.get(
        `OABUAwaitingPreInvoice/GetOABUAwaitingPreinvoiceBlocksIndicator/${companyIdRequest}`,
    );

    commit('setOABUAwaitingPreinvoiceBlocksIndicator', data.data);
};

export const GetPreinvoiceByProjectIndicator = async (
    { commit },
    companyIdRequest,
) => {
    const { data } = await settlementApi.get(
        `PreInvoiceByProject/GetPreinvoiceByProjectIndicator/${companyIdRequest}`,
    );

    commit('setPreinvoiceByProjectIndicator', data.data);
};

export const GetBillingIndicator = async ({ commit }, companyIdRequest) => {
    const { data } = await settlementApi.get(
        `Billing/GetBillingIndicator/${companyIdRequest}`,
    );

    commit('setBillingIndicator', data.data);
};

export const RecalculateByLandmarkByEvent = async ({ commit }, dataToSend) => {
    const { data } = await settlementApi.post(
        `TALandmarkByEventLogic/Recalculate`,
        dataToSend,
    );

    return data.data;
};

export const RecalculateByLandmarkByTypeActivity = async (
    { commit },
    dataToSend,
) => {
    const { data } = await settlementApi.post(
        `TALandmarkByTypeActivityLogic/Recalculate`,
        dataToSend,
    );

    return data.data;
};

export const GetOABUAwaitingPreinvoiceBlocksById = async (
    { commit },
    dataToSend,
) => {
    const { data } = await settlementApi.get(
        `OABUAwaitingPreinvoiceBlocks/${dataToSend}`,
    );

    return data.data;
};

export const ShutdownBillingBlock = async ({ commit }, dataToSend) => {
    const { data } = await settlementApi.post(
        `OABUAwaitingPreinvoiceBlocks/ShutdownBlock`,
        dataToSend,
    );

    return data;
};

export const GetInfoDateBlocks = async ({ commit }, dataToSend) => {
    const { data } = await settlementApi.post(
        `OABUAwaitingPreInvoice/GetInfoDateBlocks`,
        dataToSend,
    );

    return data.data;
};

export const GetMoneyIndicators = async ({ commit }, companyIdRequest) => {
    const { data } = await settlementApi.get(
        `OABUAwaitingPreInvoice/GetMoneyIndicators/${companyIdRequest}`,
    );

    commit('setMoneyIndicators', data.data);
};

export const GetPreinvoiceByProjectMoneyIndicator = async (
    { commit },
    dataToSend,
) => {
    const { data } = await settlementApi.post(
        `PreInvoiceByProject/GetPreinvoiceByProjectMoneyIndicator`,
        dataToSend,
    );

    commit('setPreinvoiceByProjectMoneyIndicators', data.data);
};
export const RecalculateByMixed = async ({ commit }, blockInfo) => {
    const { data } = await settlementApi.post(
        `TAMixedLogic/Recalculate`,
        blockInfo,
    );

    return data.data;
};

export const CalculateByHourInterval = async ({ commit }, blockInfo) => {
    const { data } = await settlementApi.post(
        `TAByHourLogic/CalculateByHourInterval`,
        blockInfo,
    );

    commit('setIntervalValues', data.data);
    return data.data;
};

export const GetInfoBloksForSpecialActivation = async (
    { commit },
    dataToSend,
) => {
    const { data } = await settlementApi.post(
        `OABUAwaitingPreInvoice/GetInfoBloksForSpecialActivation`,
        dataToSend,
    );
    return data.data;
};

export const ActivateBlocks = async ({ commit }, dataToSend) => {
    const { data } = await settlementApi.post(
        `OABUAwaitingPreinvoiceBlocks/ActivateBlocks`,
        dataToSend,
    );

    return data;
};

export const getInvoicesByProject = async ({ commit }, idProject) => {
    const { data } = await settlementApi.get(
        `Billing/GetByProjectId/${idProject}`,
    );
    commit('setInvoicesByProject', data.data);
    return data;
};

export const AddCommentPreinvoiceByProject = async ({ commit }, dataToSend) => {
    const { data } = await settlementApi.post(
        `PreInvoiceByProject/AddCommentPreinvoiceByProject`,
        dataToSend,
    );
    return data;
};

export const CancelBilling = async ({ commit }, dataToSend) => {
    const { data } = await settlementApi.post(
        `Billing/CancelBilling`,
        dataToSend,
    );

    return data;
};

export const CancelPreinvoice = async ({ commit }, dataToSend) => {
    const { data } = await settlementApi.post(
        `PreInvoiceByProject/CancelPreinvoice`,
        dataToSend,
    );

    return data;
};

export const getProfileTaxes = async ({ commit }) => {
    const { data } = await settlementApi.get(`ProfileTax`);
    return data;
};

export const getProfileTaxesByCompany = async ({ commit }, SearchCompanyId) => {
    const { data } = await settlementApi.get(
        `ProfileTax/GetProfileTaxesByCompanyId/${SearchCompanyId}`,
    );
    return data;
};

export const createProfileTax = async ({ commit }, newProfileTax) => {
    const { data } = await settlementApi.post(`ProfileTax`, newProfileTax);
    commit('management/resultPut', data, { root: true });
    return data;
};
export const GetDashboardByClientOrNotSettle = async (
    { commit },
    dataToSend,
) => {
    const { data } = await settlementApi.get(
        `Billing/GetDashboardByClientOrNot/${dataToSend.companyId}/${dataToSend.idClient}`,
    );
    return data.data;
};
export const updateProfileTax = async ({ commit }, profileTax) => {
    const { data } = await settlementApi.put(`ProfileTax`, profileTax);
    commit('management/resultPut', data, { root: true });
    return data;
};

export const SearchCreditNotes = async ({ commit }, dataToSend) => {
    const { data } = await settlementApi.post(
        `CreditNote/SearchCreditNotes`,
        dataToSend,
    );
    return data;
};

export const GetUnitOfPaymentForCreditNote = async (
    { commit },
    unitOfPament,
) => {
    const { data } = await settlementApi.get(
        `CurrencyAndIndexingUnit/GetUnitOfPaymentForCreditNote/${unitOfPament}`,
    );
    return data.data;
};

export const CreateCreditNote = async ({ commit }, dataToSend) => {
    const { data } = await settlementApi.post(`CreditNote`, dataToSend);
    return data.data;
};

export const GetCreditNoteById = async ({ commit }, creditNoteId) => {
    const { data } = await settlementApi.get(`CreditNote/${creditNoteId}`);
    return data.data;
};

export const SearchCashRegisterReceipt = async ({ commit }, dataToSend) => {
    const { data } = await settlementApi.post(
        `CashRegisterReceipt/SearchCashRegisterReceipt`,
        dataToSend,
    );
    return data.data;
};

export const CreateCashRegisterReceipt = async ({ commit }, dataToSend) => {
    const { data } = await settlementApi.post(
        `CashRegisterReceipt`,
        dataToSend,
    );
    return data.data;
};

export const GetCashRegisterReceiptById = async (
    { commit },
    cashRegisterReceiptId,
) => {
    const { data } = await settlementApi.get(
        `CashRegisterReceipt/${cashRegisterReceiptId}`,
    );
    return data.data;
};

export const UpdateModalPropertiesIntoPreinvoiceByProject = async (
    { commit },
    dataToSend,
) => {
    const { data } = await settlementApi.post(
        `PreInvoiceByProject/UpdateModalPropertiesIntoPreinvoiceByProject`,
        dataToSend,
    );
    return data;
};

export const sendActivityDownload = async ({ commit }, dataToSend) => {
    const { data } = await settlementApi.post(
        `OABUAwaitingRevision/ActivityDownload`,
        dataToSend,
    );
    return data;
};
export const GetNoovaState = async ({ commit }, billingId) => {
    const { data } = await settlementApi.get(
        `Billing/GetNoovaState/${billingId}`,
    );
    commit('setNoovaBillingState', data.data);
    return data.data;
};
